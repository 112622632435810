import React from 'react'
// import { FaDiscord, FaInstagram, FaYoutube } from 'react-icons/fa'
// import { ImOpera } from 'react-icons/im'
import { NavLink } from 'react-router-dom'
import { Link } from 'react-router-dom'
// import { FaCartArrowDown } from "react-icons/fa";
import "./css/footer.css"


const Footer = () => {
    return (
        <>
            <div className='contact-short mt-5'>
                {/* <div className='gap two-column large'>
                    <div className='mt-4 linee' style={{ color: "white" }}>
                        <p className='started fs-footer mt-3' ><b>Ready To Get Started</b></p>
                        <p className='fs-footer'><b>Talk to Us Today</b> </p>
                    </div>
                    <div className='get-started'>
                        <button className='button'>
                            <NavLink to="/contact" style={{ color: "black" }}> 
                                Get Started  
                            </NavLink> 
                        </button>
                    </div>
                </div> */}
            </div>

            <footer>
                {/* <div className='container-footer diff' style={{ color: "white" }}>
                    <div className='down' style={{marginTop:"3em" , display:"flex"}}>
                        <div className='footer-about' >
                            <h3 style={{ color: "white" }}>Chandan Rana</h3>
                            <p>Team Sonu Sharma</p>
                        </div>
                        <div className='footer-subscribe' >
                            <form action='#'>
                                <input type='email' id="email" placeholder='Enter Your E-mail' />
                                <input type='submit' id='submit' value="Subscribe" />
                            </form>
                        </div>
                        <div className='footer-social diff lane2' >
                            <h3 style={{ color: "white" }}>Follow Us</h3>
                            <div className='shift-icon'>

                                <div className='footer-social-icon lane'>
                                    <FaDiscord className='icons' />
                                </div>
                                <div>
                                    <FaInstagram className='icons' />
                                </div>
                                <div>
                                    <a href='#' target='_blank'>
                                        <FaYoutube className='icons' style={{ color: "white" }} />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='footer-contact' >
                            <h3 style={{ color: "white" }}>Call Us</h3>
                            <a href='tel:8929797979' style={{ color: "white" }}>+91 9788898686</a>
                        </div>
                    </div>
                </div> */}

                <div className='tss'>
                    <img src='' alt='tss'/>
                </div>

                <div className='footer-bottom' style={{ backgroundColor: "#061231f0" }}>
                    <hr />
                    <div className='shift'>
                        <div className="xyz mt-5">
                            <h1>Easily Navigate The Page</h1>

                            <ul className='mt-5'>
                                {/* <li><Link to="/" style={{color: "white", textDecoration:"none",fontSize: "1.1em"}}> Home</Link></li> */}
                                <li><Link to="/Home" style={{ color: "white", textDecoration: "none", fontSize: "1.1em" }}> Home</Link></li>
                                <li><Link to="/Product" style={{ color: "white", textDecoration: "none", fontSize: "1.1em" }}> Set Notes</Link></li>
                                <li><Link to="/Exercise" style={{ color: "white", textDecoration: "none", fontSize: "1.1em" }}> Supplement</Link></li>
                                <li><Link to="/Contact" style={{ color: "white", textDecoration: "none", fontSize: "1.1em" }}> Agriculture</Link></li>
                                <li><Link to="/About" style={{ color: "white", textDecoration: "none", fontSize: "1.1em" }}> Health Problem Us</Link></li>
                                <li><Link to="/About" style={{ color: "white", textDecoration: "none", fontSize: "1.1em" }}> Online Work</Link></li>
                                <li><Link to="/About" style={{ color: "white", textDecoration: "none", fontSize: "1.1em" }}> About Us</Link></li>

                            </ul>
                        </div>
                        <hr style={{ marginTop: "2em" }} />
                        <div className=''>
                            <div className='container-footer diff-footer'>
                                <p style={{ color: "white" }}>@{new Date().getFullYear()} All Rights Reserved</p>
                                
                            </div>
                        </div>
                        <hr />
                    </div>

                </div>
            </footer>


        </>
    )
}

export default Footer