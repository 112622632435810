import React from 'react'
import Swal from 'sweetalert2'
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';

const Contact = () => {

    // const sendto = () => {
    //     const number = "+91          ";
    //     const text = document.getElementById('name').value; 
    //     const text1 = document.getElementById('text1').value;
    //     const text2 = document.getElementById('text2').value;


    //     const url = "https://wa.link/c70quf" + number + "?text="
    //         + "text : " + text + "%0a"
    //         + "text1 : " + text1 + "%0a"
    //         + "text2 : " + text2 + "%0a%0a";

    //     window.open(url, '_blank').focus(); 
    // } 

    // const emailsend = () => {


    // } 
    const notify = () => toast("Mr Chandan Rana Will Contact You Soon ...");


    const onSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);

        formData.append("access_key", "e6a6f0ff-beb9-4850-82f9-3abc031ce4fe");

        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);

        const res = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
            body: json
        }).then((res) => res.json());

        if (res.success) {
            Swal.fire({
                title: "Great !",
                text: "Mr Chandan Rana Will Contact You Soon",
                icon: "success"
            });
        }
    };

    return (

        <form className="row g-3 mt-1" onSubmit={onSubmit} id='apply'> 
            <div className='get-in-touch mt-5'>
                Congrats ! Now Direct Connect With Official Team Sonu Sharma
            </div>
            <div className="mb-3" style={{ color: "white", backgroundColor: "transparent" }}>
                <label htmlFor="exampleFormControlInput1" className="form-label" style={{color:"aqua", backgroundColor:"transparent"}}>Enter Your Name</label>
                <input type="text" style={{color:"aqua", backgroundColor:"transparent"}} className="form-control transparent" name='name' id="exampleFormControlInput1" placeholder="Enter Your Name" />
            </div>
            <div className="mb-3">
                <label htmlFor="exampleFormControlInput1" className="form-label"  style={{color:"white"}}>Whatsapp Number</label>
                <input type="number" style={{color:"aqua", backgroundColor:"transparent"}} className="form-control transparent" name='number' id="exampleFormControlInput1" placeholder='Enter Your Whatsapp Number' required />
            </div>
            <div className="mb-3">
                <label htmlFor="text" className="form-label"  style={{color:"white"}}>Enter Your City 💬 </label>
                <textarea style={{color:"aqua", backgroundColor:"transparent"}} className="form-control transparent" name='message' id="exampleFormControlTextarea1" placeholder='Ex - New Delhi ' rows="3" required></textarea>
            </div>

            <div className="col-12 mt-5" style={{ marginBottom: "5em" }}>
                <button type="submit" onClick={notify} className="btn btn-primary">Contact us</button>
            </div>
           
        </form >


    )
}

export default Contact