import React from 'react'

const Objection = () => {
    return (
        <div style={{ backgroundColor: "transparent" }}>
            <h4 style={{ color: "white", textAlign: "center" }}>Objection Handling Questions</h4>
            <div class="accordion accordion-flush mt-5" id="accordionFlushExample">
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingOne" style={{ backgroundColor: "transparent" }}>
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne" >
                            <b> मैं सोच कर बताऊंगा कुछ दिन का समय दे दो ? </b>
                        </button>
                    </h2>
                    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">क्या सोचेंगे आप यही की करना है कि नही करना,  लेकिन जब आप सोचोगे तो कुछ सवाल होंगे आपके मन में जिसका जवाब आपके पास नहीं होंगा,  आप अपने कुछ दोस्तों और रिश्तेदारों से पूछेंगे उनको कुछ भी पता नहीं होगा, ए ठीक ऐसा ही होगा जैसे विराट से एक्टिंग करने की जानकारी लेना और शाहरुख खान से क्रिकेट का जानकारी लेना जबकि दोनों ही अपनी फील्ड के मास्टर हैं , लेकिन यह गलत होगा, अगर बिजनेस शुरू करना है तो उनसे बात करना होगा जो कर रहे हैं और सफल हैं Right !!</div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingTwo">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                            <b> मेरे पास समय नहीं है ?</b>
                        </button>
                    </h2>
                    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">1. क्या 5 साल पहले समय था या नहीं <br />
                            2. क्या आने वाला 5 साल बुरा समय होगा या नहीं<br />
                            3. जैसा चल रहा है उसमें ना समय था ना होगा कैसा रहेगा जब हमारे पास परिवार के लिए समय ही समय हो अच्छा होगा ना
                            हम सब समय चाहते हैं लेकिन कुछ ऐसा हो जाता है कि जाना चाहते हैं कोलकाता और मुंबई की ट्रेन में बैठ जाते हैं तो क्या कभी कोलकाता पहुंच सकेंगे नहीं ना अच्छा मुझे बताओ अगर पैर में कांटा लगा तो क्या करोगे सुई या पीन से निकलना होगा लेकिन जब सुई लगाओगे तो थोड़ी देर के लिए दर्द भर जाएगा लेकिन कांटा निकल जाने के बाद सारी जिंदगी के लिए दर्द दूर हो जाएगा ,यहाँ पे थोड़ा समय लगाकर और समय का कांटा निकाल कर फेंक दे फिर सारी जिंदगी टाइम ही टाइम होगा
                            बड़े बिजनेसमैन और बड़े अधिकारी , व्यापार में इसी सिद्धांत से सफल हुए हैं और आज टाइम फ्रीडम Enjoy कर रहे हैं , मैं आपकी मदद करूंगा आपकी टीम में हजारो लोग होंगे और आपके पास भी समय ही समय होगा मैं आपके साथ हूं चलिए शुरू करते हैं</div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingThree">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                            <b>  मैं सेलिंग नहीं कर सकता प्रोडक्ट्स बेचने पड़ते हैं मैं बेच नहीं सकता</b>
                        </button>
                    </h2>
                    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">1.  मैं आपको सेल्समैन लगता हूं क्या <br />
                            और अगर मुझे सेल्समैन चाहिए होती तो मैं आपके पास नहीं आता किसी कंपनी के लिये सेलिंग मॉडल पर बिजनेस करना होता तो सेल्समैन रख लेता <br /> 2. लेकिन Word of Mouth पर प्रोडक्ट मूव होते हैं हम प्रोडक्ट नहीं बेचते हैं हम सिर्फ हमेशा की तरह सलाह देते हैं और लोग लोगों को , हम बेचते नहीं लोग हमसे खरीदते हैं , और  अगर आपको सर्दी हो गई , आपने सिट्राजिन लिया और सर्दी आपकी ठीक हो गई अब आपके पड़ोसी को भी सर्दी हो गई तो आप क्या कहोगे कि क्या ले लो,  तो आप कहोगे ना की सिट्राजिन इस्तेमाल करो और अगर उसकी भी खांसी ठीक हो गई तो वह दूसरों को भी ऐसे ही सलाह देंगे
                            क्या इसमें आपने कोई सेल्स करी नहीं आपने सिर्फ एक प्रोडक्ट को शेयर किया जिससे आपको आपका बेनिफिट मिला यह काम हम बचपन से करते आ  रहे हैं लेकिन कभी पैसा नहीं मिला इस बिजनेस में आपको यही काम करना है प्रोडक्ट इस्तेमाल करना है और अच्छा लगे तो शेयर करना है और हम आपकी पूरी मदद करेंगे चलिए शुरू करते हैं  </div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingFour">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                            <b>मैं मीटिंग में नहीं जा सकता</b>
                        </button>
                    </h2>
                    <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">क्या आपके ऑफिस में मीटिंग होती है क्या उसमें इंटरटेनमेंट होता है आपको बहुत मजा आता है लेकिन फिर भी आप उस मीटिंग को अटेंड करते हो ना और सारी जिंदगी अटेंड करनी पड़ती है यहां पर एक साल तक महीने की दो-चार मीटिंग अटेंड कर लो और साल के बाद एक दो लाख की इनकम पर पहुंच जाओ तो क्या मीटिंग अटेंड करने में कोई परेशानी है , क्या एक लाख कमाने के लिए मीटिंग में नहीं आ सकते हैं</div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingFive">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                            <b> मैंने पहले भी मीटिंग अटेंड करी है मैं जानता हूं आपकी कंपनी के बारे में</b>
                        </button>
                    </h2>
                    <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">क्या मैं आपसे एक सवाल पूछ सकता हूं
                            क्या आप शाहरुख खान को जानते हो इनकी कमर का साइज क्या है उनका पसंदीदा रंग कौन सा है उनके घर में कितने बेडरूम है चलो दूसरा सवाल पूछता हूं आप मनमोहन सिंह को जानते हो मनमोहन सिंह अपने बालों में कौन सा तेल लगाते हैं उनकी पसंदीदा डिश कौन सी है ऐसे ही बहुत से लोग कंपनी को तो जानते हैं लेकिन सिर्फ ऊपर ऊपर से पूरी डिटेल किसी को नहीं पता सिर्फ मीटिंग करने से पैसा नहीं आता पैसा कमाने के लिए एक गाइड के अनुसार बिजनेस करना होता है हमारी टीम लगातार आपको कोचिंग देगी फिर अगर आपको बदलाव न लगे तो आप यह काम मत करना और ऐसा करके बहुत लोगों ने अपने सपनों को पूरा किया है</div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingSix">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                            <b> प्रोडक्ट की क्या गारंटी है जैसा आप बता रहे हो वैसा है भी या नहीं कैसे पता चलेगा</b>
                        </button>
                    </h2>
                    <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">यूज करके<br />
                            हम अपने एक्सपीरियंस से बात रहे हैं , वैसे ही आप जब प्रोडक्ट उसे करेंगे तो आपको बेनिफिट जरूर मिलेगा कितना बेनिफिट है या तो उसे करके ही पता चलेगा अगर आप 3-6 महीने उसे करके कुछ बेनिफिट ना मिले तो आगे उसे मत करिएगा घड़ी डिटर्जेंट की ऐड आपने देखी होगी पहले इस्तेमाल करें फिर विश्वास करें तभी आपने उसे किया</div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingSeven">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseThree">
                            <b>  मुझे डिस्काउंट दो दूसरा 30% दे रहा है</b>
                        </button>
                    </h2>
                    <div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">आपको पता है पेप्सी कितने की आती है<br />
                            प्रोस्पेक्ट - ₹10 की<br />
                            अगर कोई आपको ₹5 में दे तो क्या आप लेना पसंद करोगे<br />
                            प्रोस्पेक्ट - नही<br />
                            आप - क्यों नहीं , <br />
                            हो सकता है उसमें कुछ गड़बड़ हो 10 का माल पांच में मिल रहा हो धोखा हो या माल ही नकली हो आप तो जो प्रोडक्ट हमें पड़ता है अगर कोई हमें काम में दे रहा है तो उसका क्या मतलब होगा क्या आप अपने बच्चों की सेहत के साथ खिलवाड़ करना पसंद करोगे हम आपका डिस्काउंट कार्ड बनवा देते हैं ओरिजिनल प्रोडक्ट भी मिलेगा और डिस्काउंट बढ़ता ही जाएगा कोई भी डीलर प्राइस से ज्यादा नहीं डिस्काउंट दे सकता</div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingEight">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseThree">
                            <b> मेरे पास कांटेक्ट नहीं है</b>
                        </button>
                    </h2>
                    <div id="flush-collapseEight" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">आपकी शादी में कितने लोग आए थे <br />
                            प्रोस्पेक्ट 300 से 400<br />
                            आप यह सब जानकारी के थे या पेपर में ऐड देखकर बुलाया था प्रोस्पेक्ट नहीं जान पहचान के ही थे आपके मोबाइल फोन में कितने नंबर हैं<br />
                            प्रोस्पेक्ट - ढाई सौ से 300 क्या यह कॉन्टैक्ट नहीं है और हमारी वर्कशॉप होती है जिसमें हम आपको लिस्ट बनाने का आईडिया सिखाएंगे</div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingNine">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseThree">
                            <b> मेरे अंकल पड़ोसी फ्रेंड ने भी किया था लेकिन चला नहीं छोड़ दिया</b>
                        </button>
                    </h2>
                    <div id="flush-collapseNine" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">आपके पास कौन सा वाहन है प्रोस्पेक्ट मोटरसाइकिल <br />
                            आप - अपने आप चलता है कि चलाना पड़ता है<br />
                            प्रोस्पेक्ट - नहीं चलाना पड़ता है <br />
                            आप - ऐसे ही मैरिड लाइफ चलती है कि एडजस्टमेंट करके चलाना पड़ता है जॉब अपने आप  चलती है कि काम करके चलाना पड़ता है, अपने आप कुछ नहीं चलता चलाना पड़ता है दोस्त एक ही क्लास में दो स्टूडेंट्स में एक मेरिट आता है दूसरा फेल हो जाता है और जो फेल हो गया वह कहता है टीचर को पढ़ाना नहीं आया सवाल आउट ऑफ सिलेबस आया था एग्जाम में लाइट नहीं थी और बहुत कुछ और मेरिट वाला कहता है स्कूल बहुत अच्छा था टीचर बेस्ट है सारे Question सिलेबस से आए थे एग्जामिनर भी अच्छा था फेल हो गया गलती उसकी थी,  ना की टीचर की,  ना की स्कूल की , ना की एडमिशन लेने से पास होने की गारंटी नहीं मिलती वैसे ही हमारी कंपनी में ज्वाइन करके सक्सेस की गारंटी नहीं मिलती सीनियर से सीखेंगे और करेंगे तो कामयाब जरूर होंगे</div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingTen">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseThree">
                            <b>  मेरे अंकल पड़ोसी फ्रेंड से पूछ लूंगा मेरे किसी जाने वाले ने किया था उससे पूछ लूंगा</b>
                        </button>
                    </h2>
                    <div id="flush-collapseTen" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">वेरी गुड <br />
                            जरूर पूछना चाहिए<br />
                            एग्जांपल लेते हैं तीन लोग हैं आ A B C तीनों ने इट का भट्टा लगाया A सफल हो गया B और C असफल हो गए अब आपको हितों का भट्टा लगाना है आप किससे पूछेंगे,
                            लेकिन आपके पड़ोस में B, C रहते हैं सही गाइड चुनिए</div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingEleven">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseThree">
                            <b>  मैं लोगों को कन्वेंस नहीं कर सकता</b>
                        </button>
                    </h2>
                    <div id="flush-collapseEleven" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">आप बताओ कि अगर आप कन्वेंस ना होना चाहे तो क्या मैं आपको कन्वेंस कर सकता हूं <br />
                            प्रोस्पेक्ट - नहीं <br />
                            आप - हम ऐसे ही लोगों को कन्वेंस नहीं करते बस राइट इनफॉरमेशन और फैक्ट बताते हैं जिनको होना होता है वह कन्वेंस हो जाता है और हम आपके लिए काम करेंगे आपके लोगों को हम समझाएंगे</div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingTwelve">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseThree">
                            <b> मुझे MLM चैन सिस्टम बिल्कुल पसंद नहीं है</b>
                        </button>
                    </h2>
                    <div id="flush-collapseTwelve" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">आप - अच्छा यह बताओ कि आपके दादाजी के कितने भाई थे <br />
                            आप- उन सब के कितने बच्चे हैं प्रोस्पेक्ट -  उन सब के 14 बच्चे हैं <br />
                            आपका ऑफिस में सबसे बड़ी पोस्ट क्या है <br />
                            उनको कौन रिपोर्ट करता है <br />
                            चर मैनेजर <br />
                            और उन चार मैनेजर को कौन रिपोर्ट करता है <br />
                            जनरल मैनेजर<br />
                            इनको कौन रिपोर्ट करता है , <br />
                            इसे पेपर मे लिखते जाइए पर कहिए या चैन नहीं तो और क्या है हम जाने अनजाने एक चेन का ही पार्ट है और आगे भी रहेंगे हमारे पैदा होने से लेकर मरने तक हम किसी ना किसी कड़ी से जुड़े हैं हर एक चीज एक दूसरी चीज से जुड़ी, तभी यह दुनिया चलती है <br />
                            यहां भी ऐसे ही है या तो हम किसी के नेटवर्क में नौकरी करके पैसा कमाते हैं या फिर की ऐसे ही नेटवर्क बनाकर आप ज्यादा पैसा कमा सकते हैं <br />
                            आज स्कूल, कॉलेज ,स्टूडेंट हॉस्पिटल, मेडिकल स्टोर , सभी फ्रेंचाइजी मॉडल पर काम करते हैं और पैसा कमाते हैं हमें भी फ्रेंचाइजी मॉडल पर ही काम करना सीखना होगा, jio की सिम सभी इस्तेमाल करते हैं क्या jio का मालिक सबको सिम बेचता है उसका कोई एग्जीक्यूटिव ही आपको सिम पहुंचाता है सब एक चेंन से जुड़े हैं
                            आप मार्केट से प्रोडक्ट खरीदते हैं तो भी एक चैन का हिस्सा है बस फर्क यह है कि वह गरीबी की चेन है और हम जो कर रहे हैं वह आपका फायदा ही देगा इसलिए यह अमीरी की चेन है आप कौन सी चैन का हिस्सा बनना चाहेंगे यह आपको तय करना है</div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingThirteen">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThree">
                            <b>  इस कंपनी के जैसी बहुत सारी कंपनी है मैंने फलानी फलानी कंपनी ज्वाइन करी थी</b>
                        </button>
                    </h2>
                    <div id="flush-collapseThirteen" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">आप एक पेपर के बीच पर लाइन खींच दीजिए
                            दोनों कंपनी का कंपैरिजन करिए और अपनी कंपनी के बारे में बताइए
                            अपनी कंपनी की सफल उपलब्धियां को ज्यादा से ज्यादा ज्यादा जानिए और बताइए</div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingFourteen">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFourteen" aria-expanded="false" aria-controls="flush-collaFourteen">
                            <b>  हमें कुछ अपना काम करना चाहिए </b>
                        </button>
                    </h2>
                    <div id="flush-collapseFourteen" class="accordion-collapse collapse" aria-labelledby="flush-headFourteen" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">आप - जब हम कुछ शॉपिंग एजेंसी खोलते हैं तो क्या प्रोडक्ट पर हमारा लोगों होता है ?
                            या हम किसी दूसरे के लिए काम कर रहे होते हैं ?<br/>
                            प्रोस्पेक्ट - दूसरे का ही ब्रांड होता <br/>
                            क्या मुझे उस ब्रांड के लिए काम करने के बाद अपने बुढ़ापे के लिए यह बच्चों के लिए कभी रॉयल्टी इनकम बना सकते हैं
                            टाइम फ्रीडम आ सकता है<br/>
                            प्रोस्पेक्ट - गारंटी नहीं है <br/>
                            फैक्ट्री शॉप एजेंसी खोलने के बाद हमें लगता है कि हम अपना काम कर रहे हैं लेकिन वहां भी हम दूसरी कंपनी का ब्रांड को ही बड़ा बनाने के लिए काम कर रहे होते है जबकि रिस्क हमारा ही होता है बिजनेस बड़ा करके अपनी ज़रूरतें और सपने पूरे कर सकते हैं बिजनेस ऑटो पायलट पर जरूर आता है हम आपकी हेल्प करेंगे आईए शुरू करते हैं</div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingFifteen">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFifteen" aria-expanded="false" aria-controls="flush-collaFifteen">
                            <b>नेटवर्क मार्केटिंग इतना अच्छा काम है तो सभी क्यों नहीं कर लेते</b>
                        </button>
                    </h2>
                    <div id="flush-collapseFifteen" class="accordion-collapse collapse" aria-labelledby="flush-headFifteen" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">आप जीस ईश्वर देवता गुरु में विश्वास करते हैं क्या सभी लोग उनको मानते हैं
                            जो फ्रूट या कलर आपको पसंद है क्या वो सभी को पसंद है
                            आप मिठाई की दुकान पर फ्री में भी मिठाई बांटे तो क्या सब लोग आपसे मिठाई खा लेंगे
                            प्रोस्पेक्ट - नहीं
                            लेकिन कुछ लोग जरूर आपके जैसे लोग होंगे जो , एवरेज के अनुसार ऐसे ही होता है इसी तरह कुछ ड्रीमर्स आपको मिल जाएंगे जो आपके साथ बिजनेस करेंगे
                            जैसे ताश की गाड़ी में कुछ बादशाह और इक्के होते हैं वैसे ही समाज में कुछ जबरजस्त लोग होते हैं उन तक पहुंचने के बाद आप सफल हो जाते हैं वहीं कुछ जोकर भी होते हैं जिनके लाइफ का कोई लक्ष्य नहीं होता और वह आप पर भी अपना असर दिखा सकते हैं उनसे दूर रहकर ही आप कुछ बड़े काम कर सकते हैं इसमें हम आपकी हेल्प करेंगे आई ए शुरू करते हैं |</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Objection