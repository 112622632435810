import { NavLink } from 'react-router-dom'

import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import noteContext from '../context/notes/noteContext';

const Objection = () => {
    const context = useContext(noteContext);
    let navigate = useNavigate();
    const { getNotes } = context;
    useEffect(() => {
        if (localStorage.getItem('token')) {
            getNotes()
        }
        else {
            navigate("/login/");
        }

    }, [])


    return (
        <div style={{ backgroundColor: "transparent" }}>
          
                <div className='supplement' style={{color:"whitesmoke"}}>
                    <h1 style={{color: "black", padding: "0.5em" }}><h3><i>  Difference Between Supplement or Medicine</i></h3></h1>
                    <b style={{ fontSize: "1.6em", marginTop: "0.5em" }}>Supplement</b>
                    <p className="w-wrap"> <h1><b>***</b></h1> Supplement is like a Food , Which id Full-filled With Vitamnins & Minerals That Takes Our Body And Maintain Health , Supplement Has No Side-Effects.... </p>
                </div>
                <div className='supplement' style={{color:"whitesmoke"}}>
                    <b style={{ fontSize: "1.6em" }}>Medicine</b>
                    <p className="w-wrap"> <h1><b>**</b></h1>  Medicine Is a Type Of Pure Chemical Building Block , Medicine Has Side-Effect  </p>
                </div>
            {/* </div> */}
            <div class="accordion accordion-flush mt-5" id="accordionFlushExample">
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingOne" style={{ backgroundColor: "transparent" }}>
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne" >
                            <h6> <div className=" text-center mt-2 big">Calcium</div></h6>
                        </button>
                    </h2>
                    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body"> <div className='product-data'>
                            <h2>Calcium</h2>
                            <p>\_________ Calcium Is The Most Important Element In Our Body , Essential Nutrients For Bone Health To Achieve Peak Bone Mass ...
                            </p>
                            <p><b>Why Is Important To Have Healthy Bones :-</b></p>
                            <p>(1.) First, Most Calcium Found In Our Teeth & Bones <br />
                                (2.)Our Bones Support us & Allow us To Move... <br />(2.)Bones Store Minerals Such As Calcium & Phosphorous Which Help Bones Strong... <br />
                            </p>
                            <p className='product-data-price'>
                                <b> Why Calcium Most Important For You :- </b>
                            </p>

                            <p>(1.) Builds And Maintain Healthy & Strong Bones <br />
                                (2.) Muscle Contraction<br />
                                (3.) Secretion Of Harmones & Enzymes<br />
                                (4.) Sending Message To Nervous System<br />
                                (5.) Improves Muscle Function & Strength<br />
                                (6.) Builds & Support Immunity<br />
                                (7.) Reduces Risk Of Heart Disease<br />
                            </p>
                            <p className='product-data-price'>
                                <b>Note :- Market Ke Calcium Supplement Occasionally Kidney Stone Ka Problem Create Karte h , Lekin Vestige Calcium Supplement Acche Se Digest Ho Jaate Hai... </b>
                            </p>


                            <div className='view'>
                                <button><NavLink to="" style={{ color: "blue" }}>View Hand-writen Notes</NavLink></button>
                            </div>

                            <div className='view'>
                                <button><NavLink to="" style={{ color: "blue" }}>Video Link</NavLink></button>
                            </div>

                        </div></div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingTwo">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                            <h6> <figcaption className="figure-caption text-center mt-2 big">Glucosamine</figcaption></h6>
                        </button>
                    </h2>
                    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">1. क्या 5 साल पहले समय था या नहीं <br />
                            2. क्या आने वाला 5 साल बुरा समय होगा या नहीं<br />
                            3. जैसा चल रहा है उसमें ना समय था ना होगा कैसा रहेगा जब हमारे पास परिवार के लिए समय ही समय हो अच्छा होगा ना
                            हम सब समय चाहते हैं लेकिन कुछ ऐसा हो जाता है कि जाना चाहते हैं कोलकाता और मुंबई की ट्रेन में बैठ जाते हैं तो क्या कभी कोलकाता पहुंच सकेंगे नहीं ना अच्छा मुझे बताओ अगर पैर में कांटा लगा तो क्या करोगे सुई या पीन से निकलना होगा लेकिन जब सुई लगाओगे तो थोड़ी देर के लिए दर्द भर जाएगा लेकिन कांटा निकल जाने के बाद सारी जिंदगी के लिए दर्द दूर हो जाएगा ,यहाँ पे थोड़ा समय लगाकर और समय का कांटा निकाल कर फेंक दे फिर सारी जिंदगी टाइम ही टाइम होगा
                            बड़े बिजनेसमैन और बड़े अधिकारी , व्यापार में इसी सिद्धांत से सफल हुए हैं और आज टाइम फ्रीडम Enjoy कर रहे हैं , मैं आपकी मदद करूंगा आपकी टीम में हजारो लोग होंगे और आपके पास भी समय ही समय होगा मैं आपके साथ हूं चलिए शुरू करते हैं</div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingThree">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                            <h6> <figcaption className="figure-caption text-center mt-2 big">Noni</figcaption></h6>
                        </button>
                    </h2>
                    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">1.  मैं आपको सेल्समैन लगता हूं क्या <br />
                            और अगर मुझे सेल्समैन चाहिए होती तो मैं आपके पास नहीं आता किसी कंपनी के लिये सेलिंग मॉडल पर बिजनेस करना होता तो सेल्समैन रख लेता <br /> 2. लेकिन Word of Mouth पर प्रोडक्ट मूव होते हैं हम प्रोडक्ट नहीं बेचते हैं हम सिर्फ हमेशा की तरह सलाह देते हैं और लोग लोगों को , हम बेचते नहीं लोग हमसे खरीदते हैं , और  अगर आपको सर्दी हो गई , आपने सिट्राजिन लिया और सर्दी आपकी ठीक हो गई अब आपके पड़ोसी को भी सर्दी हो गई तो आप क्या कहोगे कि क्या ले लो,  तो आप कहोगे ना की सिट्राजिन इस्तेमाल करो और अगर उसकी भी खांसी ठीक हो गई तो वह दूसरों को भी ऐसे ही सलाह देंगे
                            क्या इसमें आपने कोई सेल्स करी नहीं आपने सिर्फ एक प्रोडक्ट को शेयर किया जिससे आपको आपका बेनिफिट मिला यह काम हम बचपन से करते आ  रहे हैं लेकिन कभी पैसा नहीं मिला इस बिजनेस में आपको यही काम करना है प्रोडक्ट इस्तेमाल करना है और अच्छा लगे तो शेयर करना है और हम आपकी पूरी मदद करेंगे चलिए शुरू करते हैं  </div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingFour">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                            <h6> <figcaption className="figure-caption text-center mt-2 big">Flax Seed oil</figcaption></h6>
                        </button>
                    </h2>
                    <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingFive">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                            <h6> <figcaption className="figure-caption text-center mt-2 big">CMD</figcaption></h6>
                        </button>
                    </h2>
                    <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingSix">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                            <h6> <figcaption className="figure-caption text-center mt-2 big">Co-Enzyme Q-10</figcaption></h6>
                        </button>
                    </h2>
                    <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingSeven">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseThree">
                            <h6> <figcaption className="text-center mt-2 big">Ganoderma</figcaption></h6>
                        </button>
                    </h2>
                    <div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body"> <div className='product-data'>
                            <h4>Ganoderma ( King of Herbs )</h4>
                            <p>\_________ Gano -- चमकदार
                                & derma -- skin
                            </p>
                            <p><b>** Works by 3 Way :-</b></p>
                            <p>(1.) Scanning the body , And Work on it -- Like Antivirus <br />
                                (2.) Detoxification -- Cell Work Level & Clean Cell & Make Healthy <br />(3.) Immunity Booster -- सही पोषण लेने में help करता हैं
                                <br />
                            </p>
                            <p className='product-data-price'>
                                <b>* Health Benefits of Ganoderma :- </b>
                            </p>

                            <p>(1.) For Brain Health <br />
                                (2.) For Immune System<br />
                                (3.) For Cancer Treatment<br />
                                (4.) For Heart Health <br />
                                (5.) For Diebetes<br />
                                (6.) For Weight Loss<br />
                                (7.) For Digestive System<br />
                                (8.) For Over-all Health<br />
                            </p>
                            <p className='product-data-price'>
                                <b>*** No Side-Effect at All </b>
                            </p>

                            <div className='view'>
                                <button><NavLink to="https://drive.google.com/file/d/1U-ritFLM8ierInm1f3QtbFOcIEoGIEVo/view?usp=drive_link" style={{ color: "blue" }}>View Hand-writen Notes</NavLink></button>
                            </div>
                            <div className='view'>
                                <button><NavLink to="" style={{ color: "blue" }}>Video Link</NavLink></button>
                            </div>

                        </div></div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingEight">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseThree">
                            <h6> <figcaption className="figure-caption text-center mt-2 big">Spirullina</figcaption></h6>
                        </button>
                    </h2>
                    <div id="flush-collapseEight" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingNine">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseThree">
                            <h6> <figcaption className="figure-caption text-center mt-2 big">Colostrum</figcaption></h6>
                        </button>
                    </h2>
                    <div id="flush-collapseNine" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingTen">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseThree">
                            <h6> <figcaption className="text-center mt-2 big">Sea Buckthorn</figcaption></h6>
                        </button>
                    </h2>
                    <div id="flush-collapseTen" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body"> <div className='product-data'>
                            <h2>Sea - Buckthorn</h2>
                            <p>\_________ Called as Himalyan Berry , Iska Root 200 ft Niche Hota hai , Plants Me Sabse Uch Sthan Diya Gaya Hai...
                            </p>
                            <p><b>** Why Should We Take :-</b></p>
                            <p>(1.) Astronaut Ko Sea - Buckthorn Diya Jata Hai , Taki Energy Ki Kami Na Ho |<br />
                                (2.) Ye , Anti Aging ,, Anti Cancer & Anti Disease H , Jise Vitamins Aur Minerals KA Bhandaar Kaha Jata Hai <br />(3.) Isme<br />..... Orange Se 12 X Jayda -- Vitamin C <br /> .....Caarot Se 3 X Jayada -- Vitamin A  Milta hai<br />
                                <br />
                            </p>
                            <p className='product-data-price'>
                                <b>* More Health Benefits of Sea - Buckthorn :- </b>
                            </p>

                            <p>(1.) More Anti-Oxidants <br />.......<b>\___Ye Ladte Hai Free Radical Se & Save Us </b> <br />
                                (2.) Maintain High Energy<br />
                                (3.) Reduce Head-ache , Dizinessn & Increases Mental Attention<br />
                                (4.) Stamina Building <br />
                                (5.) Cardiac And Diabetic Benefits   <br />
                                (5.) Skin Benefits & Gastric Benefits  <br />
                                (5.) Best Benefit In Liver    <br />

                            </p>

                            <div className='view'>
                                <button><NavLink to="" style={{ color: "blue" }}>View Hand-writen Notes</NavLink></button>
                            </div>
                            <div className='view'>
                                <button><NavLink to="" style={{ color: "blue" }}>Video Link</NavLink></button>
                            </div>

                        </div></div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingEleven">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseThree">
                            <h6> <figcaption className="text-center mt-2 big">L-Arginine</figcaption></h6>
                        </button>
                    </h2>
                    <div id="flush-collapseEleven" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">  <div className='product-data'>
                            <h2>L - Arginine </h2>
                            <p>\_________ This Is Chemical Building Block , Jise Amino Acid Bhi Kaha Jaata Hai...
                            </p>
                            <p><b>** Health Benefits of L - Arginine :-</b></p>
                            <p>(1.) Heart Aur Blood Artery (Dhamniya) Ko Healthy Rakhta Hai <br />
                                (2.) High BP & Colestrol Ko Control Karta Hai <br />(3.) Badhti Age & Galat Khan-Paan Se Nijaat Dilata Hai
                                <br />
                            </p>
                            <p className='product-data-price'>
                                <b>* More Health Benefits of L -Arginine :- </b>
                            </p>

                            <p>(1.) Veins Ka Sikudna Aur Tight Ho Jaana <br />
                                (2.) Sexual System Ko Best Karna<br />
                                (3.) Badhti Age Ka Effect Kam Karna<br />
                                (4.) Blood Sugar Ko Control Karna <br />
                                (5.) Stamina Ko Improve Karna<br />

                            </p>
                            <p className='product-data-price'>
                                <b>Note:- Ye Veins Ko Open Kar Deta Hai , Jisse Blood Ka Flow Achhi Trah Se Ho Sake </b>
                            </p>
                            <p className='product-data-price'>
                                <b>Usage:- One Packet 200 ML Water / Juice Ke Saath Le Sakte Hai </b>
                            </p>

                            <div className='view'>
                                <button><NavLink to="https://drive.google.com/file/d/1U-ritFLM8ierInm1f3QtbFOcIEoGIEVo/view?usp=drive_link" style={{ color: "blue" }}>View Hand-writen Notes</NavLink></button>
                            </div>
                            <div className='view'>
                                <button><NavLink to="" style={{ color: "blue" }}>Video Link</NavLink></button>
                            </div>

                        </div></div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingTwelve">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseThree">
                            <h6> <figcaption className="figure-caption text-center mt-2 big">Protein Powder</figcaption></h6>
                        </button>
                    </h2>
                    <div id="flush-collapseTwelve" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingThirteen">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThree">
                            <h6> <figcaption className="text-center mt-2 big">Hair Skin & Nail</figcaption></h6>
                        </button>
                    </h2>
                    <div id="flush-collapseThirteen" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">    <div className='product-data'>
                            <h2>Hair Skin & Nail Supplement</h2>
                            <p>\_________ Mainly , It is Build For Our Hair , Skin & Nail
                            </p>
                            <p><b>** Capsules Contain :-</b></p>
                            <p>(1.) Green Tea Extract<br />
                                (2.) Borage Oil <br />(3.) Amino Acid -- (Lysine , Methionine Cysteine)<br />(4.) Vitamins (E , B6 , Folic Acid)
                                <br /> (5.) Minerals (Mg , Zn , Se , Biotin , Iron) <br />
                            </p>
                            <p className='product-data-price'>
                                <b>* Health Benefits of Hair Skin & Nail :- </b>
                            </p>

                            <p>(1.) Ye Hair , Skin & Nail Ko Nourish Karta Hai <br />
                                (2.) Ye Nails Ko Thickness Aur Strong Karta Hai<br />
                                (3.) Helps Retain Skin Moisture & Prevent Skin Inflamation<br />
                                (4.) Helps To Delat the Signs of Aging <br />
                                (5.) Helps in Promoting Hair Growth   <br />
                                (5.) Helps in Maintaing the Volume of Hair  <br />
                                (5.) Maintain Over All Hair ,Skin & Nail    <br />

                            </p>

                            <div className='view'>
                                <button><NavLink to="" style={{ color: "blue" }}>View Hand-writen Notes</NavLink></button>
                            </div>
                            <div className='view'>
                                <button><NavLink to="" style={{ color: "blue" }}>Video Link</NavLink></button>
                            </div>

                        </div></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Objection