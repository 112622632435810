import { NavLink } from 'react-router-dom'
import team from "./images/team.jpg"
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import noteContext from '../context/notes/noteContext';
import hosting1 from "./images/hosting1.jpg"
import hosting2 from "./images/hosting2.jpg"
import offlineprospecting from "./images/offlineprospecting.jpg"


const HotMarket = () => {
    const context = useContext(noteContext);
    let navigate = useNavigate();
    const { getNotes } = context;
    useEffect(() => {
        if (localStorage.getItem('token')) {
            getNotes()
        }
        else {
            navigate("/login/");
        }

    }, [])


    return (
        <div style={{ backgroundColor: "transparent" }}>
            <img src={team} class="img-fluid img-webinar" alt="online"></img>
            <div class="shadow-lg p-3 mb-3 bg-body-tertiary rounded mt-3 center">
                <NavLink to="" style={{ color: "white", textDecoration: "none" }}><h5><b>*** Warm Market Work RoadMap </b></h5></NavLink>
            </div>
           

            <div class="accordion accordion-flush mt-5" id="accordionFlushExample">
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingOne" style={{ backgroundColor: "transparent" }}>
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne" >
                            <h5><b>Make Your Name List</b></h5>
                        </button>
                    </h2>
                    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body"> <div className='product-data sklls-box'>
                            <div>
                                <p class="fw-semibold fs"><h3><b>Create Your Name List</b></h3></p>
                            </div>
                            <table class="table table-dark table-striped mt-1">
                                <thead>
                                    <tr>

                                        <th scope="col">Name</th>
                                        <th scope="col">Contact No.</th>
                                        <th scope="col">Location</th>
                                        <th scope="col">Profession</th>
                                        <th scope="col">Rating (1,2,3,4,5)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>

                                        <td>Chandan</td>
                                        <td>7324837251</td>
                                        <td>Delhi</td>
                                        <td>Work</td>
                                        <td>5</td>
                                    </tr>
                                    <tr>

                                        <td>Sarita</td>
                                        <td>9838847363</td>
                                        <td>Up</td>
                                        <td>Work</td>
                                        <td>4</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">Rekha</td>
                                        <td>Jharkhand</td>
                                        <td>Work</td>
                                        <td>2</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">kaushalya</td>
                                        <td>Ranchi</td>
                                        <td>Work</td>
                                        <td>5</td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">khushbu</td>
                                        <td>Ramgarh</td>
                                        <td>Work</td>
                                        <td>4</td>
                                    </tr>


                                </tbody>



                            </table>

                            <div style={{ textAlign: "center" }}>
                                <p class="fw-semibold mt-2 fs" ><h3><b>Prospecting on Your List</b></h3></p>
                                <img src={offlineprospecting} class="img-fluid img-prospecting" alt="online"></img>
                            </div>

                            <div>
                                <p class="fw-semibold mt-5 fs"><h3><b>Create 5 Star Hot List ( 4 )  <br /></b></h3></p>
                            </div>

                            <div>
                                <table class="table table-dark table-striped mt-1">
                                    <thead>
                                        <tr>

                                            <th scope="col">Name</th>
                                            <th scope="col">Contact No.</th>
                                            <th scope="col">Location</th>
                                            <th scope="col">5 Star Rating <br />(1,2,3,4,5)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>

                                            <td>Chandan</td>
                                            <td>7324837251</td>
                                            <td>Delhi</td>
                                            <td>5</td>
                                        </tr>
                                        <tr>

                                            <td>kaushalya</td>
                                            <td>7324837251</td>
                                            <td>Delhi</td>
                                            <td>5</td>
                                        </tr>
                                        <tr>

                                            <td>khushbu</td>
                                            <td>7324837251</td>
                                            <td>Delhi</td>
                                            <td>4</td>
                                        </tr>
                                        <tr>

                                            <td>haridwar</td>
                                            <td>7324837251</td>
                                            <td>Delhi</td>
                                            <td>4</td>
                                        </tr>
                                        <tr>

                                            <td>nisha</td>
                                            <td>7324837251</td>
                                            <td>Delhi</td>
                                            <td>3</td>
                                        </tr>

                                    </tbody>

                                </table>
                            </div>
                        </div></div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingTwo">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                            <h5> <b> Make Your Goal List</b></h5>
                        </button>
                    </h2>

                    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">  <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" style={{ flexDirection: "column" }} >
                            <div className='adjust'>
                                <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-2 center">
                                    <h3><b>* Three Types of Dreams</b></h3>
                                </div>
                                <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-1 center">
                                    <h4><b>1. Financial Dream</b></h4>
                                </div>
                                <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt center">
                                    <h4> <b>2. Materialistic Dream</b></h4>
                                </div>
                                <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt center">
                                    <h4> <b>3. Personal Dream</b></h4>
                                </div>
                            </div>
                            <hr />

                            <p class="fw-semibold mt-3 fs"><b style={{ color: "" }}>* Short Term Goal (6 Months) </b></p>
                            <p class="fw-semibold mt-2 fs"><b style={{ color: "" }}>* Mid Term Goal (1 Year) </b></p>
                            <p class="fw-semibold mt-1 fs"><b style={{ color: "" }}>* Long Term Goal (3-5 Year) </b></p>

                            <hr />

                            <div className='adjust1'>
                                <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-5 center">
                                    <h3> <b>Rule :- S.M.A.R.T</b></h3>
                                </div>


                                <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-1 center" >
                                    <h5 style={{ lineHeight: "2em" }}> <b>S - Specific</b><br />
                                        <b> M </b>- Measurable<br />
                                        <b> A</b> - Achievable<br />
                                        <b> R</b> - Realistic<br />
                                        <b>T</b> - Time - Bound
                                    </h5>
                                </div>
                            </div>
                            <div className='adjust'>
                                <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-2 center">
                                    <h4><ul> <b>Short Term Goal</b></ul><br />
                                        1. Buy Mobile Cover<br />
                                        2. .................<br />
                                        3. .................<br />
                                        4. .................
                                    </h4>
                                </div>
                                <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-2 center">
                                    <h4><ul> <b>Mid Term Goal</b></ul><br />
                                        1. Buy a Mobile<br />
                                        2. .................<br />
                                        3. .................<br />
                                        4. .................
                                    </h4>
                                </div>
                                <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded mt-2 center">
                                    <h4><ul> <b>Long Term Goal</b></ul><br />
                                        1. Buy a Car<br />
                                        2. .................<br />
                                        3. .................<br />
                                        4. .................
                                    </h4>
                                </div>
                            </div>
                        </div> </div>
                    </div>









                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingThree">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                            <h5> <b>Your 1st Traning </b></h5>
                        </button>
                    </h2>
                    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">  <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" style={{ flexDirection: "column" }} >


                            <div class="p-3 mb-5 bg-body-tertiary rounded center">
                                <NavLink to="https://www.youtube.com/live/zpFpLj5-vKY?si=g-r3v4OI_WbmpBbt" style={{ color: "white" }}> <b>Your 1st Traning </b></NavLink>
                            </div>
                        </div> </div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingThree">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                            <h5> <b>Your 2nd Traning </b></h5>
                        </button>
                    </h2>
                    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">  <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" style={{ flexDirection: "column" }} >


                            <div class="p-3 mb-5 bg-body-tertiary rounded center">
                                <NavLink to="https://www.youtube.com/live/wFhHYizzcb8?feature=shared" style={{ color: "white" }}> <b>Your 2nd Traning </b></NavLink>
                            </div>
                        </div> </div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingThree">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                            <h5> <b>Your 3rd Traning </b></h5>
                        </button>
                    </h2>
                    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">  <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" style={{ flexDirection: "column" }} >


                            <div class="p-3 mb-5 bg-body-tertiary rounded center">
                                <NavLink to="https://www.youtube.com/live/NDyq4k_6Iz0?feature=shared" style={{ color: "white" }}> <b>Your 3rd Traning </b></NavLink>
                            </div>
                        </div> </div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingFour">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                            <h5> <b>Your 4th Traning </b></h5>
                        </button>
                    </h2>
                    <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">  <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" style={{ flexDirection: "column" }} >


                            <div class="p-3 mb-5 bg-body-tertiary rounded center">
                                <NavLink to="https://www.youtube.com/live/JIj6n5ciMds?feature=shared" style={{ color: "white" }}> <b>Your 4th Traning </b></NavLink>
                            </div>
                        </div> </div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingFive">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                            <h5> <b>Your 5th Traning </b></h5>
                        </button>
                    </h2>
                    <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">  <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" style={{ flexDirection: "column" }} >


                            <div class="p-3 mb-5 bg-body-tertiary rounded center">
                                <NavLink to="https://www.youtube.com/live/ooWzqTjeoeE?si=JZR8yyNdFDrQsU0W" style={{ color: "white" }}> <b>Your 5th Traning </b></NavLink>
                            </div>
                        </div> </div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingSix">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                            <h5> <b>Product Traning With Demo </b></h5>
                        </button>
                    </h2>
                    <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">  <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" style={{ flexDirection: "column" }} >


                            <div class="p-3 mb-5 bg-body-tertiary rounded center">
                                <NavLink to="https://www.youtube.com/live/NDyq4k_6Iz0?feature=shared" style={{ color: "white" }}> <b>Product Traning With Demo </b></NavLink>
                            </div>
                        </div> </div>
                    </div>
                </div>
               




            </div>
        </div>
    )
}

export default HotMarket