import { NavLink } from 'react-router-dom'
import team from "./images/team.jpg"
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import noteContext from '../context/notes/noteContext';
import hosting1 from "./images/hosting1.jpg"
import hosting2 from "./images/hosting2.jpg"

const WorkRoadmap = () => {
    const context = useContext(noteContext);
    let navigate = useNavigate();
    const { getNotes } = context;
    useEffect(() => {
        if (localStorage.getItem('token')) {
            getNotes()
        }
        else {
            navigate("/login/");
        }

    }, [])


    return (
        <div style={{ backgroundColor: "transparent" }}>
            <img src={team} class="img-fluid img-webinar" alt="online"></img>
            <div class="shadow-lg p-3 mb-3 bg-body-tertiary rounded mt-3 center">
                <NavLink to="" style={{ color: "white", textDecoration: "none" }}><h5><b>*** Cold Market Means That You Don't Know Those People </b></h5></NavLink>
            </div>
            <button className='webinar' type='submit' style={{ borderRadius: "3em" }}> <NavLink to="https://youtu.be/qXXe8hiH668?si=VobUQ9A3SwCI4BIW" style={{ color: "black" }}><h5> Presentation Link By Sonu Sir</h5></NavLink></button>
            <button className='webinar' type='submit' style={{ borderRadius: "3em" }}> <NavLink to="https://youtu.be/e77xHzB2uK0?si=4iO4bIkR02FoJN8f" style={{ color: "black" }}><h5> 10 Min Presentation Link</h5></NavLink></button>


            <div class="accordion accordion-flush mt-5" id="accordionFlushExample">
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingOne" style={{ backgroundColor: "transparent" }}>
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne" >
                            <h5><b>** Learn Only these 5 Skills </b></h5>
                        </button>
                    </h2>
                    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body"> <div className='product-data sklls-box'>
                            <div className='sklls-box' style={{ color: "white" }}>
                                <div class=" p-3 mb-5 bg-body-tertiary rounded center">
                                    <NavLink to="/Prospecting" style={{ color: "white" }} > <h5> <b> 1. Prospecting</b></h5></NavLink>
                                </div>
                                <div class=" p-3 mb-5 bg-body-tertiary rounded center">
                                    <NavLink to="/Invitation" style={{ color: "white" }} > <h5><b> 2. Invitation</b></h5></NavLink>
                                </div>
                                <div class=" p-3 mb-5 bg-body-tertiary rounded center">
                                    <NavLink to="https://gamma.app/docs/Welcome-z8epu17tr002tac?mode=doc#card-thsga06pj5khep3" style={{ color: "white" }} > <h5> <b> 3. Presentation</b></h5></NavLink>
                                </div>
                                <div class=" p-3 mb-5 bg-body-tertiary rounded center">
                                    <NavLink to="/Edification" style={{ color: "white" }} > <h5> <b> 4. Edification</b></h5></NavLink>
                                </div>
                                <div class=" p-3 mb-5 bg-body-tertiary rounded center">
                                    <NavLink to="" style={{ color: "white" }}><h5> <b> 5. Follow Up</b></h5></NavLink>
                                </div>
                                <div class=" p-3 mb-5 bg-body-tertiary rounded center">
                                    <NavLink to="" style={{ color: "white" }}> <h5><b> 6. Closing</b></h5></NavLink>
                                </div>

                            </div>
                        </div></div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingTwo">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                            <h5> <b>** Go Master on Your Work</b></h5>
                        </button>
                    </h2>
                    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body"> <div style={{ color: "white" }}>
                            <div class="shadow-lg p-3 mb-3 bg-body-tertiary rounded center" >
                                <h2 className="fs-prospecting" style={{ lineHeight: "2em" }}><h4><b>3 Major Skills Sikhne Ke Baad Kya Kare </b></h4><br />
                                    <b> 1. </b>Prospecting Kijiye Aur Usi Time Invitaion<br />
                                    <b> 2. </b>Send a Two Min Video , Before Presentation<br />
                                </h2>
                            </div>

                            <h4 className='center-home mt-5'>If She is Girl then Send</h4>

                            <div className="shadow-lg p-3 mb-3 bg-body-tertiary rounded center-home"><h3 style={{ marginTop: "3em", color: "aqua" }}></h3>
                                <NavLink to="https://youtu.be/xnFecZ95j8s?si=s7XUKE5HN2NugF7q"
                                    style={{
                                        boxShadow: "white 3px 5px 9px", 
                                        height: "8vh",
                                        width: "50%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textDecoration: "none"
                                    }}

                                > <h5 className='paperplan'><b style={{ color: "aqua" }}> Mohini Jaiswaal</b></h5><br /><br /></NavLink>


                                <NavLink to="https://youtu.be/2xD5xFKREYw?si=sSSDWIwSX7NviZq4"
                                    style={{
                                        boxShadow: "white 3px 5px 9px",
                                        height: "8vh",
                                        width: "50%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textDecoration: "none"
                                    }}

                                > <h5 className='paperplan'><b style={{ color: "aqua" }}> Purnima Singh </b></h5><br /><br /></NavLink>

                            </div>
                            <h4 className='center-home mt-0'>If He is Boy then send </h4>

                            <div className="shadow-lg p-3 mb-5 bg-body-tertiary rounded center-home"><h3 style={{ marginTop: "3em", color: "aqua" }}></h3>
                                <NavLink to="https://youtu.be/h6xBYeK6w6I?si=0O8L5DjAzLII2s_P"
                                    style={{
                                        boxShadow: "white 3px 5px 9px",
                                        height: "8vh",
                                        width: "50%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textDecoration: "none"
                                    }}

                                > <h5 className='paperplan'><b style={{ color: "aqua" }}> Virendra Pratap</b></h5><br /><br /></NavLink>


                                <NavLink to="https://youtu.be/gyvyrbijzYU?si=lEmP1T58v5252W5I"
                                    style={{
                                        boxShadow: "white 3px 5px 9px",
                                        height: "8vh",
                                        width: "50%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textDecoration: "none"
                                    }}

                                > <h5 className='paperplan'><b style={{ color: "aqua" }}> Satyendra gurjar </b></h5><br /><br /></NavLink>

                            </div>



                            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" >
                                <h2 className="fs-prospecting" style={{ lineHeight: "1.5em" }}>3. Presentation Attend Krwane Se Pehle Zoom App Ka Video Send<br />
                                    <NavLink to="https://drive.google.com/file/d/1KhpRWRT1Ub_ykmiG4uHKICuXMLB2rDMB/view"
                                        style={{
                                            boxShadow: "white 3px 5px 9px",
                                            height: "8vh",
                                            width: "70%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            textDecoration: "none"
                                        }}

                                    > <h5 className='paperplan'><div style={{ color: "aqua" }}> Zoom App Video</div></h5><br /><br /></NavLink>

                                </h2>
                            </div>
                            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" >
                                <h2 className="fs-prospecting" style={{ lineHeight: "1.5em" }}>4. After Webinar- Ask , Pure Webinar Me Sabse Badhiya Kya Laga Aapko<br />
                                </h2>
                            </div>
                            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" >
                                <h2 className="fs-prospecting" style={{ lineHeight: "1.5em" }}>5. Edification Kijiye , Jinse Baat Karwana Chahte Hai<br />
                                </h2>
                            </div>
                            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" >
                                <h2 className="fs-prospecting" style={{ lineHeight: "1.5em" }}>6. Proper Time Fix Kijiye Baat Karwane Ka & Send This Video,
                                    <NavLink to="https://youtu.be/ZJAWSSJWSJQ?si=uoVw0En0nS86MNGc" style={{ color: "aqua" }}> <b>Video Link</b></NavLink>
                                    <br />
                                </h2>
                            </div>
                            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" >
                                <h2 className="fs-prospecting" style={{ lineHeight: "1.5em" }}>7. Proper Baat Karwane Ke Baad , Follow Up Achhe Se Kijiye , Video Send Karke Bhi -
                                    {/* <NavLink to="/Namelist" style={{ color: "black" }}> <b>Video Link</b></NavLink> */}
                                    <br />
                                </h2>
                            </div>

                            <h4 className='center-home mt-5'>If She is Girl then Send</h4>

                            <div className="shadow-lg p-3 mb-5 bg-body-tertiary rounded center-home"><h3 style={{ marginTop: "3em", color: "aqua" }}></h3>
                                <NavLink to="https://youtu.be/x6xHXPJfo1M?si=iD8H7F1cwZ4-rniH"
                                    style={{
                                        boxShadow: "white 3px 5px 9px",
                                        height: "8vh",
                                        width: "50%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textDecoration: "none"
                                    }}

                                > <h5 className='paperplan'><b style={{ color: "aqua" }}> Mohini Jaiswaal</b></h5><br /><br /></NavLink>


                                <NavLink to="https://youtu.be/5LXManfClms?si=3kRCML1u6Po-eh3h"
                                    style={{
                                        boxShadow: "white 3px 5px 9px",
                                        height: "8vh",
                                        width: "50%",
                                        display: "flex",
                                        justifyContent: "center", 
                                        alignItems: "center", 
                                        textDecoration: "none"
                                    }}

                                > <h5 className='paperplan'><b style={{ color: "aqua" }}> Purnima Singh </b></h5><br /><br /></NavLink>

                            </div>
                            <h4 className='center-home mt-5'>If He is Boy then Send</h4>

                            <div className="shadow-lg p-3 mb-5 bg-body-tertiary rounded center-home"><h3 style={{ marginTop: "3em", color: "aqua" }}></h3>
                                <NavLink to="hhttps://youtu.be/SI9auzn6dDY?si=mZKWaNclwGFTq2Er"
                                    style={{
                                        boxShadow: "white 3px 5px 9px",
                                        height: "8vh",
                                        width: "50%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textDecoration: "none"
                                    }}

                                > <h5 className='paperplan'><b style={{ color: "aqua" }}> Ravi Ranjan</b></h5><br /><br /></NavLink>


                                <NavLink to="https://youtu.be/tvz3dTkgik0?si=_Pzfr0-N8H0VPg26"
                                    style={{
                                        boxShadow: "white 3px 5px 9px",
                                        height: "8vh",
                                        width: "50%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textDecoration: "none"
                                    }}

                                > <h5 className='paperplan'><b style={{ color: "aqua" }}> Virendra Pratap[ on Stage] </b></h5><br /><br /></NavLink>

                            </div>

                            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" >
                                <h2 className="fs-prospecting" style={{ lineHeight: "1.5em" }}>8. Closing Ek Process Hai , Agar Payment Na Aaye To Upline Se Phir Ek Baar Time Lekar Baat Karwaiye <br />
                                </h2>
                            </div>

                        </div></div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingThree">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                            <h5> <b> * Right Way to Host Meeting  </b></h5>
                        </button>
                    </h2>
                    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">  <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" style={{ flexDirection: "column" }} >
                            <h4 style={{ lineHeight: "2em", color: "white" }}> <b>Right Way to Host a Meeting</b><br />
                            </h4>
                            <img src={hosting1} class="img-fluid img-hosting1" alt="online"></img>
                            <img src={hosting2} class="img-fluid img-hosting2" alt="online"></img>
                        </div> </div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingFour">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                            <h5> <b>Objection Handling Questions </b></h5>
                        </button>
                    </h2>
                    <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">  <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center" style={{ flexDirection: "column" }} >


                            <div class="p-3 mb-5 bg-body-tertiary rounded center">
                                <NavLink to="/Objection" style={{ color: "white" }}> <b>Objection Handling Questions </b></NavLink>
                            </div>
                        </div> </div>
                    </div>
                </div>




            </div>
        </div>
    )
}

export default WorkRoadmap