import React from 'react'
import { NavLink } from 'react-router-dom'
const crmedia = () => {
    return (
        <div>
            <div class="shadow-lg p-3 mb-5 bg-body-tertiary" style={{ height: "12vh" }} >
                <h2 className="fs-prospecting" style={{ lineHeight: "1.6em" }}><h3><b>Chandan Rana Profile</b></h3><br />

                </h2>
            </div>

            <div className='rowside' style={{ display: "flex" }}>
                <div class="p-3 mt-5 mb-5 bg-body-tertiary rounded cntr">
                    <h2 className="fs-services" style={{ lineHeight: "1.6em", color: "white" }}><h5 style={{ color: "white" }}><b>Web Developer By Profession , Expert Networker By Choice</b></h5><br />
                        <b> 1. </b><b >Name:- </b> Chandan Rana<br />
                        <b> 2. </b><button className='primary'><NavLink to="/CRmedia" style={{ backgroundColor: "blue", color: "white" }}> <b>...Click Here...</b></NavLink></button> <br />
                    </h2>
                </div>

                <hr />
            </div>

            <div className='rowside' style={{ display: "flex" }}>
                <div class="boxShadow p-3 mt-5 mb-5 bg-body-tertiary rounded"  >
                    <h2 className="fs-services" style={{ lineHeight: "1.6em", color: "white" }}><h4 style={{ color: "aqua" }}><b>Instagram Id</b></h4><br />
                        <b> 1. </b><b >Insta Id </b>- office.chandanrana_<br />
                        <b> 2. </b><button className='primary'><NavLink to="https://www.instagram.com/office.chandanrana_/" style={{ backgroundColor: "blue", color: "white" }}> <b>Insta Link , click Here</b></NavLink></button> <br />
                    </h2>
                </div>
                <div class="boxShadow p-3 mt-5 mb-5 bg-body-tertiary rounded"  >
                    <h2 className="fs-services" style={{ lineHeight: "1.6em", color: "white" }}><h4 style={{ color: "aqua" }}><b>YouTube Id</b></h4><br />
                        <b> 1. </b><b >YouTube Id </b>- ChandanranaUNIQUE<br />
                        <b> 2. </b><button className='primary'><NavLink to="https://www.youtube.com/@ChandanranaUNIQUE" style={{ backgroundColor: "blue", color: "white" }}> <b>YouTube Link , click Here</b></NavLink></button> <br />
                    </h2>
                </div>
                <div class="boxShadow p-3 mb-5 mt-5 bg-body-tertiary rounded"  >
                    <h2 className="fs-services" style={{ lineHeight: "1.6em", color: "white" }}><h4 style={{ color: "aqua" }}><b>Facebook Id</b></h4><br />
                        <b> 1. </b><b >Facebook Id </b>- Chandan Rana<br />
                        <b> 2. </b><button className='primary'><NavLink to="https://www.facebook.com/profile.php?id=100034922888706" style={{ backgroundColor: "blue", color: "white" }}> <b>FB Link , click Here</b></NavLink></button> <br />
                    </h2>
                </div>
                <hr />
            </div>
        </div>
    )
}

export default crmedia