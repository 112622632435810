import React from 'react'
import { NavLink } from 'react-router-dom'

const Objection = () => {
    return (
        <div style={{ backgroundColor: "transparent" }}>
            <h4 style={{ color: "white", textAlign: "center" }}>Objection Handling Questions</h4>
            <div class="accordion accordion-flush mt-5" id="accordionFlushExample">
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingOne" style={{ backgroundColor: "transparent" }}>
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne" >
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}><h5><b> Hair Growth Problem</b></h5>
                            </div>
                        </button>
                    </h2>
                    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body"><div className='product-data'>
                            <h2>Hair Growth Problem</h2>
                            <p>\_________ Best Supplement For Hair Growth , Vestige Provides For you
                            </p>
                            <h4><b>100% Result</b></h4>
                            <p> (1.) Noni Supplement <br />(2.) Hair Skin & Nail Supplement <br />
                                (3.)Amla &b Alovera <br />(4.)Hair oil & Anti-Hairfall Shampoo <br />
                            </p>
                            <p className='product-data-price'>
                                <h4><b>Feedback Video By People</b></h4>
                            </p>


                            <div className="shadow-lg p-3 mb-5 bg-body-tertiary rounded center-health"><h3 style={{ color: "white" }}></h3>
                                <NavLink to="#"
                                    style={{
                                        boxShadow: "white 3px 5px 9px",
                                        height: "8vh",
                                        width: "50%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textDecoration: "none"
                                    }}

                                > <h5 className='paperplan'><b style={{ color: "white" }}> Video Link 1</b></h5><br /><br /></NavLink>

                                <NavLink to="#"
                                    style={{
                                        boxShadow: "white 3px 5px 9px",
                                        height: "8vh",
                                        width: "40%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textDecoration: "none"
                                    }}

                                > <h5 className='paperplan'><b style={{ color: "white" }}> Video Link 2</b></h5><br /><br /></NavLink>
                            </div>
                        </div></div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingTwo">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}><h5><b> Cholestrol Kam ya Motapa Kam Karna</b></h5>
                            </div>
                        </button>
                    </h2>
                    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body"> <div className='product-data'>
                            <h2>Motapa Kam Karna</h2>
                            <p>\_________ Best Supplement For Weight Loss
                            </p>
                            <h4><b>100% Result</b></h4>
                            <p> (1.) Veslim Shake <br />(2.) Veslim Tea <br />
                                (3.)Veslim Capsules <br />
                            </p>
                            <p className='product-data-price'>
                                <h4><b>Feedback Video By People</b></h4>
                            </p>
                            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
                                <NavLink to="" style={{ color: "blue" }}> <b>Video Link 1...., </b></NavLink> <br />
                                <NavLink to="" style={{ color: "blue" }}> <b> Video Link 2</b></NavLink>
                            </div>
                        </div></div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingThree">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}><h5><b> Knee Problem Best Supplement</b></h5>
                            </div>
                        </button>
                    </h2>
                    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body"> <div className='product-data'>
                            <h2>Knee Pain Problem</h2>
                            <p>\_________ Best Supplement For Knee Pain Problem
                            </p>
                            <h4><b>100% Result</b></h4>
                            <p> (1.) Noni Supplement <br />(2.) Flax oil <br />
                                (3.) Calcium <br />(4.) Glucosamine <br />
                            </p>
                            <p className='product-data-price'>
                                <h4><b>Feedback Video By People</b></h4>
                            </p>
                            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
                                <NavLink to="" style={{ color: "blue" }}> <b>Video Link 1...., </b></NavLink> <br />
                                <NavLink to="" style={{ color: "blue" }}> <b> Video Link 2</b></NavLink>
                            </div>
                        </div> </div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingFour">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}><h5><b> Kamar Se Feet Tak (Sciatica , Pain)</b></h5>
                            </div>
                        </button>
                    </h2>
                    <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingFive">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}><h5><b> Kidney Stone</b></h5>
                            </div>
                        </button>
                    </h2>
                    <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body"> <div className='product-data'>
                            <h2>Kidney Stone Problem </h2>
                            <p>\_________ Best Supplement For Kidney Stone Problem
                            </p>
                            <h4><b>100% Result</b></h4>
                            <p> (1.) Alobvera <br />(2.) Ganoderma <br />
                                (3.) Flax oil <br />(4.) Cranberry <br /> (5. ) Foot Patch
                            </p>
                            <p className='product-data-price'>
                                <h4><b>Feedback Video By People</b></h4>
                            </p>
                            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
                                <NavLink to="" style={{ color: "blue" }}> <b>Video Link 1...., </b></NavLink> <br />
                                <NavLink to="" style={{ color: "blue" }}> <b> Video Link 2</b></NavLink>
                            </div>
                        </div></div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingSix">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                            <div className="box1-product1" style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}><h5><b> Liquor Se Freedom</b></h5>
                            </div>
                        </button>
                    </h2>
                    <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body"> <div className='product-data'>
                            <h2>Saraab Se Chhutkara</h2>
                            <p>\_________ Best Supplement From Liquor Freedom
                            </p>
                            <h4><b>100% Result</b></h4>
                            <p> (1.) Noni Supplement <br />(2.) Foot Patches  <br />
                                (3.) Alovera <br />(4.) Liver Health <br /> (5.) Flax Oil <br />(6.) Ganoderma <br /> (7.) Gano Tooth Paste
                            </p>


                            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
                                <NavLink to="https://youtu.be/rylIM9VTWxw?si=ydGPkRVUptvwJxjU" style={{ color: "blue" }}><h5> <b>Complete Knowledge Video ( Mitali joshi)</b></h5></NavLink>
                            </div>
                            <p className='product-data-price'>
                                <h4><b>Feedback Video By People</b></h4>
                            </p>

                            <div class="shadow-lg p-3 mb-5 bg-body-tertiary rounded center">
                                <NavLink to="" style={{ color: "blue" }}> <b>Video Link 1...., </b></NavLink> <br />
                                <NavLink to="" style={{ color: "blue" }}> <b> Video Link 2</b></NavLink>
                            </div>
                        </div></div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingSeven">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseThree">
                            <b>  मुझे डिस्काउंट दो दूसरा 30% दे रहा है</b>
                        </button>
                    </h2>
                    <div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingEight">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseThree">
                            <b> मेरे पास कांटेक्ट नहीं है</b>
                        </button>
                    </h2>
                    <div id="flush-collapseEight" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingNine">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseThree">
                            <b> मेरे अंकल पड़ोसी फ्रेंड ने भी किया था लेकिन चला नहीं छोड़ दिया</b>
                        </button>
                    </h2>
                    <div id="flush-collapseNine" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingTen">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseThree">
                            Mere Pass Time Nhi Hai
                        </button>
                    </h2>
                    <div id="flush-collapseTen" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingEleven">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseThree">
                            Mere Pass Time Nhi Hai
                        </button>
                    </h2>
                    <div id="flush-collapseEleven" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingTwelve">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwelve" aria-expanded="false" aria-controls="flush-collapseThree">
                            Mere Pass Time Nhi Hai
                        </button>
                    </h2>
                    <div id="flush-collapseTwelve" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                    </div>
                </div>
                <div class="accordion-item" style={{ backgroundColor: "transparent", color: "white" }}>
                    <h2 class="accordion-header" id="flush-headingThirteen">
                        <button class="accordion-button collapsed" style={{ backgroundColor: "rgb(5 54 46)", borderRadius: "6px", color: "white" }} type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThirteen" aria-expanded="false" aria-controls="flush-collapseThree">
                            Mere Pass Time Nhi Hai
                        </button>
                    </h2>
                    <div id="flush-collapseThirteen" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div class="accordion-body">Placeholder content for this accordion, which is intended to demonstrate the <code>.accordion-flush</code> class. This is the third item's accordion body. Nothing more exciting happening here in terms of content, but just filling up the space to make it look, at least at first glance, a bit more representative of how this would look in a real-world application.</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Objection